import React, { useState, useEffect, useCallback, useRef } from "react";
import styled from "styled-components";
import Button from "../../../components/Button";
import DatePicker from "react-datepicker";
import InputComponent from "../../../components/Input";
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import getHotels from "../../../providers/Hotels";
import getHotelsKayak from "../../../providers/KayakHotels";
import getHotelsGoogle from "../../../providers/GoogleHotels";
import getRates from "../../../providers/Rates";
import getRatesKayak from "../../../providers/Kayak";
import getRatesGoogle from "../../../providers/Google";
import getHotelsNearbyArea from "../../../providers/HotelsNearbyArea";
import "react-datepicker/dist/react-datepicker.css";
import { debounce } from "lodash";
import {
  AiFillPlusSquare,
  AiFillMinusSquare,
  AiOutlineLoading3Quarters,
} from "react-icons/ai";
import { toast } from "react-toastify";
import Chart from "react-apexcharts";
import moment from "moment";
import ReCAPTCHA from "react-google-recaptcha";
import { addDays, compareDesc } from "date-fns";
import axios from "axios";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";

const providers = {
  search: {
    t: getHotels,
    k: getHotelsKayak,
    g: getHotelsGoogle,
  },
  rates: {
    t: getRates,
    k: getRatesKayak,
    g: getRatesGoogle,
  }
}

export default function TableDates({ nextPage, handleError }) {
  const defaultLoadingMessages = {
    message1:
      "Now Bezla is searching thousands of websites to detect the lowest rate for each hotel selected.",
    message2:
      "Once the results are shown, you may point your cursor to each date and determine where Bezla is capturing the rate shown on the table.",
  };

  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [hotelQuery, setHotelQuery] = useState();
  const [hotelOptions, setHotelOptions] = useState([]);
  const [comparisonList, setComparisonList] = useState([]);
  const [comparisonCloneList, setComparisonCloneList] = useState([]);
  const [originsList, setOriginsList] = useState([]);
  const [resultsHotels, setResultsHotels] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingHotels, setIsLoadingHotels] = useState(false);
  const [loadingHotels, setLoadingHotels] = useState(false);
  const [captchaValue, setCaptchaValue] = useState(null);
  const [captchaIsVisible, setCaptchaIsVisible] = useState(true);
  const [isMissingResults, setIsMissingResults] = useState(false);
  const [mainHotelNearby, setMainHotelNearby] = useState("");

  const { provider } = useParams();

  let previousLoadingMessage = defaultLoadingMessages.message2;
  let currentLoadingMessage = defaultLoadingMessages.message1;
  let nextLoadingMessage = defaultLoadingMessages.message2;

  let navigate = useNavigate();

  const handleChangeHotelQuery = async (e) => {
    // set hotel query
    setHotelQuery(e);
    // use Debounce for API calls and not overloading the server
    await debounceGetHotels(e);
    setLoadingHotels(true);
    setIsLoadingHotels(true);
  };

  useEffect(() => {
    if (hotelOptions && hotelOptions.length !== 0) {
      setIsLoadingHotels(false);
    } else {
      setIsLoadingHotels(true);
    }
  }, [hotelOptions, isLoadingHotels]);

  // get date one week ahead
  useEffect(() => {
    endDate.setDate(endDate.getDate() + 7)
  }, [])

  // debounce function
  const debounceGetHotels = useCallback(
    debounce(
      (e) => {
        providers.search[provider](e).then((res) => {
          setHotelOptions(res);
          setLoadingHotels(false);
        })
      }, 2000
    ),
    []
  );

  const addToComparisonList = async (e, obj) => {
    e.preventDefault();
    const exists = comparisonList.some((item) => item.hotelId === obj.hotelId);

    if (exists) {
      toast.warn("This hotel is already on the Comparison List.");
    } else {
      const newHotelOptions = hotelOptions.filter(
        (item) => item.hotelId !== obj.hotelId
      );

      if (obj.tag) {
        await setHotelOptions([...newHotelOptions]);
        await setComparisonList([...comparisonList, obj]);
      } else {
        if (mainHotelNearby !== obj.name) {
          setMainHotelNearby(obj.name);
          await setComparisonList([...comparisonList, obj]);
          const nearbyArea = await getHotelsNearbyArea({
            geoId: obj.geoId,
            startDate: startDate,
            endDate: endDate,
            mainHotel: obj.name,
          });
          console.log("nearby hotels 1 >> ", nearbyArea);
          const filteredNearby = nearbyArea.filter(item => item.hotelId !== obj.hotelId);
          const removeOldNearbyOptions = newHotelOptions.filter(item => !item.tag);
          await setHotelOptions([...removeOldNearbyOptions, ...filteredNearby]);
        } else {
          setMainHotelNearby(obj.name);
          await setComparisonList([...comparisonList, obj]);
          const nearbyArea = await getHotelsNearbyArea({
            geoId: obj.geoId,
            startDate: startDate,
            endDate: endDate,
            mainHotel: obj.name,
          });
          console.log("nearby hotels 2 >> ", nearbyArea);
          const filteredNearby = nearbyArea.filter(item => item.hotelId !== obj.hotelId)
          await setHotelOptions([...newHotelOptions, ...filteredNearby]);
        }
      }
    }
  };

  const removeFromComparisonList = async (e, obj) => {
    e.preventDefault();
    const removedComparationList = comparisonList.filter(
      (item) => item.hotelId !== obj.hotelId
    );

    if (obj.tag) {
      if (mainHotelNearby === obj.mainHotel) {
        await setComparisonList([...removedComparationList]);
        await setHotelOptions([...hotelOptions, obj]);
      } else {
        await setComparisonList([...removedComparationList]);
      }
    } else {
      if (mainHotelNearby === obj.name) {
        setMainHotelNearby("");
        const removeNearbyOptions = hotelOptions.filter(item => !item.tag);
        // const removeNearbyComparationList = removedComparationList.filter(item => !item.tag);
        await setComparisonList([...removedComparationList]);
        await setHotelOptions([...removeNearbyOptions, obj]);
      } else {
        await setComparisonList([...removedComparationList]);
        await setHotelOptions([...hotelOptions, obj]);
      }
    }
  };

  const onSubmitButton = async () => {
    if (captchaValue) {
      const res = await verifyCaptcha(captchaValue);
      setCaptchaIsVisible(false);
      if (res) {
        await runGraphCalculations()
          .then((res) => setCaptchaIsVisible(true))
          .catch((err) => setCaptchaIsVisible(true));
      } else {
        setCaptchaIsVisible(true);
        toast.warn("Please revalidate the reCAPTCHA");
      }
    } else {
      setCaptchaIsVisible(true);
      toast.warn("Please fill the reCAPTCHA.");
    }
  };

  const changeProviders = async () => {
    if (provider === "t") {
      setIsMissingResults(false);
      navigate(`/rates/k`);
    } else if (provider === "k") {
      setIsMissingResults(false);
      navigate(`/rates/t`);
    }
  }

  const verifyCaptcha = async (id) => {
    let requestURL = window?.location.href.includes("localhost")
      ? "http://localhost:5001/rates-bb81c/us-central1/getCaptcha"
      : "https://us-central1-budget-effc0.cloudfunctions.net/getCaptchaBudget";

    try {
      const { data } = await axios.post(requestURL, {
        token: id,
      });

      return data.success ? true : data === "OK" ? true : false;
    } catch (error) {
      return false;
    }
  };

  const runGraphCalculations = async () => {
    try {
      if (comparisonList?.length < 1) {
        throw new Error("You must select at least one hotel.");
      }

      if (!startDate) {
        throw new Error("Start date invalid.");
      }

      if (!endDate) {
        throw new Error("End date invalid.");
      }

      if (new Date(startDate).getTime() > new Date(endDate).getTime()) {
        throw new Error("The check_in is greaten than check_out.");
      }

      await setComparisonCloneList(comparisonList);

      setIsLoading(true);

      const id = setInterval(() => {
        previousLoadingMessage = currentLoadingMessage;
        currentLoadingMessage = nextLoadingMessage;
        nextLoadingMessage = previousLoadingMessage;
        document.getElementById("loadingMessage").innerHTML =
          currentLoadingMessage;
      }, 5000);

      var rates;
      rates = await providers.rates[provider](
        comparisonList.map((item) => {
          return {
            hotelId: item.hotelId,
            hotelName: item.name,
            hotelAddress: provider === "t" ?
              item.address.address + "-" + item.address.city + "," + item.address.country
              : item.address.address,
          };
        }),
        startDate.toISOString(),
        endDate.toISOString()
      )

      if (rates.length === 0) {
        setIsMissingResults(true);
      }

      const sortedArray = [...rates];
      sortedArray.sort((a, b) => {
        const indexA = comparisonList.findIndex(item => item.hotelId === a.hotelId);
        const indexB = comparisonList.findIndex(item => item.hotelId === b.hotelId);
        return indexA - indexB;
      })

      resetLoadingMessages();
      setIsLoading(false);
      clearInterval(id);
      await setResultsHotels(sortedArray);

      const origins = sortedArray.map((item, index) => {
        let name =
          comparisonList.length > 0
            ? comparisonList.filter((cpl) => cpl.hotelId === item.hotelId)[0]
              ?.shortName
            : "";
        let codigos = item?.dates
          ? item?.dates.map((data, index) => {
            return data ? data?.code.filter((c) => c).join("; ") : "";
          })
          : [];
        return {
          name: name,
          codes: codigos,
        };
      });

      setOriginsList(origins);

      const element = document.getElementById("priceChart");
      if (element) { element.scrollIntoView({ behavior: 'smooth', block: "center" }) };

    } catch (err) {
      console.error(err);
      toast.error(err.message);
    }
  };

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  const resetLoadingMessages = () => {
    currentLoadingMessage = defaultLoadingMessages.message1;
    nextLoadingMessage = defaultLoadingMessages.message2;
  };

  return (
    <Container>
      <InputContainer>
        <InputDates>
          <DateFieldContainer>
            <span>Start Date</span>
            <DatePicker
              selected={startDate}
              onChange={(date) => setStartDate(date)}
              selectsStart
              startDate={startDate}
              endDate={endDate}
              minDate={new Date()}
            />
          </DateFieldContainer>
          <DateFieldContainer>
            <span>End Date</span>
            <DatePicker
              selected={endDate}
              onChange={(date) => setEndDate(date)}
              selectsEnd
              startDate={startDate}
              endDate={endDate}
              minDate={startDate}
              maxDate={addDays(startDate, 89)}
            />
          </DateFieldContainer>
          <div></div>
        </InputDates>
        <InputHotelSearch>
          <span>Search Hotel</span>
          <InputComponent
            variant="secondary"
            placeholder={"Type the hotel or location..."}
            onChange={(e) => handleChangeHotelQuery(e.target.value)}
            value={hotelQuery}
          />
        </InputHotelSearch>
      </InputContainer>
      <TableContainer>
        <Table>
          <THead>
            <tr>
              <td>
                <h3>Matching Results Found</h3>
              </td>
            </tr>
            <HeaderTableRow>
              <td>
                <strong>Name</strong>
              </td>
              <td>
                <strong>Location</strong>
              </td>
            </HeaderTableRow>
          </THead>
          <TBody>
            {loadingHotels ? (
              <>
                <LoadingHotel>
                  <AiOutlineLoading3Quarters size={25} />
                  <strong>Loading...</strong>
                </LoadingHotel>
              </>
            ) : (
              <>
                {Array.isArray(hotelOptions) && !isLoadingHotels ? (
                  hotelOptions?.map((item, index) => {
                    return (
                      <ResultsTableRow key={`${item.shortName} - ${index}`}>
                        {item.tag ?
                          <NearbyTagTd>
                            <span>{item.shortName}</span>
                            <NearbyTagSpan>(Nearby)</NearbyTagSpan>
                          </NearbyTagTd>
                          :
                          <td>
                            <span>
                              {item.shortName}
                            </span>
                          </td>
                        }
                        <td>
                          {provider === 't' ?
                            <span>
                              {item.address.address} - {item.address.city},{" "}
                              {item.address.country}
                            </span>
                            :
                            <span>
                              {item.address.address}
                            </span>
                          }
                        </td>
                        <td>
                          <button onClick={(e) => addToComparisonList(e, item)}>
                            <AiFillPlusSquare size={28} />
                          </button>
                        </td>
                      </ResultsTableRow>
                    );
                  })
                ) : (
                  <td>The table is empty.</td>
                )}
              </>
            )}
          </TBody>
        </Table>
        <SecondaryTable>
          <THead>
            <tr>
              <td>
                <h3>Hotel Comparison List</h3>
              </td>
            </tr>
            <ResultsTableRow>
              <td>
                <span>
                  <strong>Name</strong>
                </span>
              </td>
            </ResultsTableRow>
          </THead>
          <TBody>
            {comparisonList &&
              comparisonList?.map((item, index) => {
                return (
                  <ResultsTableRow key={`${item.shortName} - ${index}`}>
                    <td>
                      <span>
                        {item.name} ({item.address.address})
                      </span>
                    </td>
                    <td>
                      <button
                        onClick={(e) => removeFromComparisonList(e, item)}
                      >
                        <AiFillMinusSquare size={28} />
                      </button>
                    </td>
                  </ResultsTableRow>
                );
              })}
          </TBody>
        </SecondaryTable>
      </TableContainer>
      <CaptchaContainer>
        {captchaIsVisible && (
          <ReCAPTCHA
            sitekey="6Ld_S8MdAAAAACAkzMl0Pfsc3eMUDGXWjv2C_lgT"
            onChange={(item) => setCaptchaValue(item)}
            onErrored={() => setCaptchaValue(null)}
          />
        )}
      </CaptchaContainer>
      <ButtonsContainer>
        <Button label="RUN" onClick={() => onSubmitButton()} />
      </ButtonsContainer>
      {resultsHotels.length > 0 && (
        <>
          <ChartContainer id="priceChart">
            <h3>Price charts</h3>
            <Chart
              options={{
                colors: [
                  "#008FFB",
                  "#00E396",
                  "#FEB019",
                  "#FF4560",
                  "#775DD0",
                  "#2E8B57",
                  "#8B0000",
                  "#191970",
                  "#FF0000",
                  "#FFFF00",
                ],
                chart: {
                  id: "basic-bar",
                  toolbar: {
                    show: true,
                    tools: {
                      download: false
                    }
                  },
                },
                xaxis: {
                  categories: resultsHotels[0]?.dates.map((item) => {
                    return moment(item.date).format(`MM/DD/YYYY`);
                  }),
                },
                yaxis: {
                  title: {
                    text: "Price",
                  },
                },

                animations: {
                  enabled: true,
                  easing: "linear",
                  dynamicAnimation: {
                    speed: 1000,
                  },
                },
                tooltip: {
                  followCursor: true,
                  onDatasetHover: {
                    highlightDataSeries: true,
                  },
                  custom: function ({ series, seriesIndex, dataPointIndex, w }) {
                    return `<div class="arrow_box" style="diplay:flex;flex-direction:column;width:calc(auto+ 50px);margin:10px">
                       <span style="display: flex;flex-direction:row">
                         <div
                           style="margin:auto;width:10px;height:10px;background-color:${w.globals.colors[seriesIndex]
                      }">
                         </div>
                         <div style="margin-left:10px">
                         ${w.config.series[seriesIndex].name}:\t<b>${series[seriesIndex][dataPointIndex]
                      }</b>
                         </div>
                       </span>
                       <div>
                         <p>Sites:
                           ${originsList
                        ?.map((el, i) => {
                          return el.name ===
                            w.config.series[seriesIndex].name
                            ? "<b>" +
                            el.codes[dataPointIndex]
                              .split(",")
                              .join("") +
                            "</b>"
                            : "";
                        })
                        .join("")
                        .replace(", ", "")}
                           </p>
                       </div>
                     </div>`;
                  },
                },
              }}
              series={resultsHotels.map((item, i) => {
                return {
                  name:
                    comparisonCloneList.length > 0
                      ? comparisonCloneList?.filter(
                        (cpl) => cpl.hotelId === item.hotelId
                      )[0]?.shortName
                      : "",
                  data: item.dates.map((arrItem) => arrItem.rate),
                };
              })}
              type="line"
              width="100%"
              height="100%"
            />
          </ChartContainer>
        </>
      )}
      {resultsHotels.length > 0 && (
        <TableContainer>
          <ResultsTable>
            <THeadPrice>
              <tr>
                <td>
                  <h3>Lowest prices for selected date range and hotels</h3>
                </td>
              </tr>
              <TableRowPrice>
                <td className="bold">Date</td>
                {resultsHotels &&
                  resultsHotels.map((item, index) => {
                    return (
                      <td className="hotelInfos" key={index}>
                        <span className="bold">
                          {comparisonCloneList.length
                            ? comparisonCloneList?.filter(
                              (cpl) => cpl.hotelId === item.hotelId
                            )[0]?.shortName
                            : ""}
                        </span>
                        {
                          provider === "t" ?
                            <span className="light">
                              {comparisonCloneList.length
                                ? comparisonCloneList?.filter(
                                  (cpl) => cpl.hotelId === item.hotelId
                                )[0]?.address.address + ", " + comparisonCloneList?.filter(
                                  (cpl) => cpl.hotelId === item.hotelId
                                )[0]?.address.city
                                : ""}
                            </span>
                            :
                            <span className="light">
                              {comparisonCloneList.length
                                ? comparisonCloneList?.filter(
                                  (cpl) => cpl.hotelId === item.hotelId
                                )[0]?.address.address
                                : ""}
                            </span>
                        }
                      </td>
                    );
                  })}
              </TableRowPrice>
            </THeadPrice>
            <TBodyPrice>
              {resultsHotels[0]?.dates.map((item) => (
                <TableRowPrice
                  className={
                    moment(item.date).isoWeekday() === 5 ||
                      moment(item.date).isoWeekday() === 6
                      ? "highlighted"
                      : ""
                  }
                  key={`${moment(item.date).format(`MM/DD/YYYY`)}`}
                >
                  <td className="bold">
                    <span>{moment(item.date).format(`MM/DD/YYYY`)}</span>
                  </td>
                  {resultsHotels.map((hotelValue, index) => {
                    return (
                      <td
                        className="column hotel-price-item"
                        key={`td-${index}`}
                      >
                        {/* <OverlayTrigger
                          key={`overlay-${index}`}
                          placement='bottom'
                          overlay={
                            <Tooltip style={{ display:'flex', 'border-radius': '4px', color: '#fff', background: '#7e7e7e'}} id={`tooltip-${index}`}>
                              Sites:{" "}
                              {hotelValue?.dates
                                ?.find((dates) => dates?.date === item.date)
                                ?.code?.slice(0, 5)
                                ?.join("; ")
                                ?.replace(", ", "")}
                            </Tooltip>
                          }
                        > */}
                        <span>
                          {hotelValue.dates.filter(
                            (arrData) => arrData.date === item.date
                          )[0]?.retryError ?
                            "Data Error"
                            :
                            hotelValue.dates.filter(
                              (arrData) => arrData.date === item.date
                            )[0].rate !== 0
                              ? "$" +
                              hotelValue.dates.filter(
                                (arrData) => arrData.date === item.date
                              )[0].rate
                              : "No Availability"
                          }
                        </span>
                        {/* Sites:{" "} */}
                        <span className="providers">
                          {hotelValue?.dates
                            ?.filter((dates) => dates?.date === item.date)
                            .flatMap((foundItem) => {
                              const newProviders = [];
                              const uniqueProviders = [];
                              foundItem.code.slice(0, 5).forEach((provider) => {
                                if (!uniqueProviders.includes(provider)) {
                                  uniqueProviders.push(provider);
                                  console.log("unique >> ", uniqueProviders);
                                  newProviders.push(provider);
                                }
                              });
                              const providerSpans = newProviders.map((provider) => {
                                return (
                                  <span key={provider}>
                                    {provider}
                                  </span>
                                );
                              });
                              return providerSpans;
                            })}
                        </span>
                      </td>
                    );
                  })}
                </TableRowPrice>
              ))}
            </TBodyPrice>
          </ResultsTable>
        </TableContainer>
      )}
      {isLoading && (
        <LoadingModal>
          <div>
            <AiOutlineLoading3Quarters />
            <strong>Loading...</strong>
            <span id="modal-loading-percent"></span>
            <h3 id="loadingMessage">{defaultLoadingMessages.message1}</h3>
          </div>
        </LoadingModal>
      )}
      {isMissingResults && (
        <MissingResultsModal>
          <div>
            <strong>It looks like we had a hiccup on our end.</strong>
            <span>Please reselect, and we'll get things sorted.</span>
            <Button label="Okay" onClick={() => changeProviders()} />
          </div>
        </MissingResultsModal>
      )}
    </Container>
  );
}

const InputContainer = styled.div`
  font: Open Sans, serif;
  color: #575757;
  width: 100%;
  margin-bottom: 1rem;
`;

const InputDates = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  font-size: 1.25rem;
  font-weight: 600;
  margin-bottom: 2.5rem;

  & > span {
    margin-bottom: 1rem;
    color: #575757;
  }
`;

const InputHotelSearch = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 1.25rem;
  font-weight: 600;
  margin-bottom: 2.5rem;

  & > span {
    margin-bottom: 0.5rem;
    font-family: "Open Sans", serif;
  }
`;

const Table = styled.table`
  font: Open Sans, serif;
  color: #575757;
  width: 63%;
  margin-bottom: 2.5rem;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
  border-radius: 4px;
  height: auto;
  min-height: 500px;
`;

const SecondaryTable = styled.table`
  font: Open Sans, serif;
  color: #575757;
  width: 35%;
  margin-bottom: 2.5rem;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
  border-radius: 4px;
`;

const ResultsTable = styled.table`
  position: relative;
  display: block;
  font: Open Sans, serif;
  color: #575757;
  width: 100%;
  margin-bottom: 2.5rem;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
  border-radius: 4px;
  font-family: "Open Sans", serif;
`;

const THead = styled.thead`
  & > tr {
    & > td {
      & > h3 {
        font-size: 1.25rem;
        font-weight: 600;
        margin-top: 0.5rem;
        margin-left: 0.5rem;
      }
    }
  }
`;

const TBody = styled.tbody`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 0.5rem;
  height: 25rem;
  height: auto;
  min-height: 500px;
`;

const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: center;
  margin-bottom: 10rem;

  & > button {
    margin-left: 3rem;
  }
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const TableContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  margin-bottom: 5rem;
  height: auto;
  min-height: 500px;
  font-family: "Open Sans", serif;
`;

const HeaderTableRow = styled.tr`
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 70px;
  margin-left: 5px;
  width: 97%;
  border-bottom: 1px solid #e7eaee;
  justify-content: space-between;

  & > td {
    min-width: 19.25rem;
    margin-right: 10px;
    font-weight: 700;
  }
`;

const ResultsTableRow = styled.tr`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin-left: 5px;
  width: 97%;
  border-bottom: 1px solid #e7eaee;

  & > td {
    display: flex;
    width: 100%;
    flex-direction: row;
    align-items: center;
    margin-right: 1rem;
    margin-top: 5px;
    margin-bottom: 10px;

    &:last-child {
      width: auto;
    }
  }

  & > td > button {
    color: #2563eb;
    border-radius: 2px;
    margin-right: 0.3rem;
    border-color: transparent;
    background: transparent;
    border-style: solid;
    cursor: pointer;
  }
`;

const DateFieldContainer = styled.div`
  --tw-ring-color: red;
  div.react-datepicker__day--selected {
    background: #333333;
    --tw-ring-color: red;
  }

  div.react-datepicker__day--in-selecting-range {
    background: rgba(51, 51, 51, 0.6);
  }

  div.react-datepicker__day--in-range {
    background: #333333;

    &:after {
      background: red;
    }
  }

  div.react-datepicker__day--highlighted-custom-1 {
    background: #cecece;
  }

  .react-datepicker__input-container input {
    display: block;
    width: 75%;
    font-size: large;
    border: none;
    color: inherit;
    font-family: "Open Sans", serif;
  }

  & > span {
    font-family: "Open Sans", serif;
  }
`;

const ChartContainer = styled.div`
  width: auto;
  min-height: 34rem;
  padding: 1rem;
  margin-bottom: 4.5rem;

  display: flex;
  flex-direction: column;

  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
  border-radius: 4px;

  & > h3 {
    padding: 0 1rem;
    font-size: 1.5rem;
    font-weight: 600;
    color: #575757;
    font-family: "Open Sans", serif;
  }
`;

const LoadingModal = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1000;
  background: rgba(0, 0, 0, 0.4);
  font-family: "Open Sans", sans-serif;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  & > div {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: #333333;
    max-width: 40%;

    background: white;
    padding: 2rem 2rem 1rem 2rem;

    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
    border-radius: 4px;

    & > svg {
      animation: spin 2s linear infinite;

      @keyframes spin {
        0% {
          transform: rotate(0deg) scale(3);
        }
        100% {
          transform: rotate(360deg) scale(3);
        }
      }
    }

    & > strong {
      padding-top: 2rem;
    }

    & > h3 {
      padding-top: 1.5rem;
      padding-bottom: 0.5rem;
      text-align: center;
      font-weight: 400;
      font-size: 1.125rem;
    }
  }
`;

const MissingResultsModal = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1000;
  background: rgba(0, 0, 0, 0.4);

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  & > div {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: #333333;
    max-width: 40%;

    background: white;
    padding: 2rem 2rem 1rem 2rem;

    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
    border-radius: 4px;

    & > span {
      margin-bottom: 2rem;
    }
  }
`;

const LoadingHotel = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  margin-top: 100px;
  color: #333333;

    & > svg {
      animation: spin 2s linear infinite;
      width: 4rem;

      @keyframes spin {
        0% {
          transform: rotate(0deg) scale(3);
        }
        100% {
          transform: rotate(360deg) scale(3);
        }
      }
    }

    & > strong {
      padding-top: 45px;
      padding-left: 7px;
    }
  }
`;

const THeadPrice = styled.thead`
  display: flex;
  flex-direction: column;
  padding: 1rem 1rem 0 1rem;
  position: sticky;
  top: 96px;
  background-color: #ffffff;
  z-index: 1000;

  & tr > td > h3 {
    font-size: 1.5rem;
    font-weight: 600;
  }
`;

const TBodyPrice = styled.tbody`
  height: auto;
  width: auto;
  padding: 0 1rem;
  display: flex;
  flex-direction: column;
  overflow-x: scroll;
`;

const TableRowPrice = styled.tr`
  min-height: 4.2rem;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding-top: 1rem;
  padding-bottom: 0.5rem;
  border-bottom: 1px solid #e7eaee;
  width: 100%;

  & > td {
    width: 12rem;
    padding-right: 1rem;

    &.bold {
      font-weight: 600;
    }

    &.hotelInfos {
      flex-direction: column;
      justify-content: center;
      display: grid;
    }

    &.center {
      display: flex;
      flex-direction: row;
      justify-content: center;
    }

    &.column {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      text-align: center;
    }

    & > span {
      &.bold {
        font-weight: 600;
      }
  
      &.light {
        font-size: 0.85rem;
        color: #828282;
      }
    }
  }


  .providers {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-size: 0.85rem;
    color: #828282;
  }

  &.highlighted {
    background-color: #e6e3e3;
  }
`;

const CaptchaContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-bottom: 1rem;
  padding-left: 3rem;
`;

const NearbyTagTd = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: start;
  margin-right: 1rem;
  margin-top: 5px;
  margin-bottom: 10px;
`;

const NearbyTagSpan = styled.span`
  color: #a3a3a3;
  font-style: italic;
`;